<template>
  <div class="right-menu">
    <p class="menu-title mb-4">{{ $t('overview') }}</p>
    <p class="menu-content mb-4" :class="path.includes('overview') ? 'active' : ''" @click="handleClick('overview')">{{ $t('nlt-smartbus?') }}</p>
    <!-- <p class="menu-content mb-4" @click="handleClick('feature')">Lợi ích khi sử dụng ứng dụng</p> -->
    <p class="menu-content mb-4" :class="path.includes('manual') ? 'active' : ''" @click="handleClick('manual')">{{ $t('install') }}</p>
    <div class="separator mb-4"></div>
    <p class="menu-title mb-4">{{ $t('find-station') }}</p>
    <p class="menu-content mb-4" :class="path.includes('search-station') ? 'active' : ''" @click="handleClick('search-station')">{{ $t('search-station-smartbus') }}</p>
    <p class="menu-content mb-4" :class="path.includes('realtime-tracking') ? 'active' : ''" @click="handleClick('realtime-tracking')">{{ $t('waitingtime') }}</p>
    <p class="menu-content mb-4" :class="path.includes('search-router') ? 'active' : ''" @click="handleClick('search-router')">{{ $t('feature-title4') }}</p>
    <div class="separator mb-4"></div>
    <p class="menu-title mb-4">{{ $t('route-search') }}</p>
    <p class="menu-content mb-4" :class="path.includes('router-info') ? 'active' : ''" @click="handleClick('router-info')">{{ $t('router-info') }}</p>
    <p class="menu-content mb-4" :class="path.includes('search-schedule') ? 'active' : ''" @click="handleClick('search-schedule')">{{ $t('schedule-search') }}</p>
    <p class="menu-content mb-4" :class="path.includes('rating') ? 'active' : ''" @click="handleClick('rating')">{{ $t('rating') }}</p>
  </div>
</template>

<script>
export default {
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  data(){
    return {
      path: this.$root._route.fullPath,
    }
  },
  methods: {
    handleClick(route) {
      this.$router.push(`/guideline/${route}`);
    },
    fetchData() {
      this.path = this.$root._route.fullPath;
    },
  },
};
</script>

<style scoped>
.separator {
  background: #e6e8ec;
  height: 1px;
}
.menu-content {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Menu */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  cursor: pointer;
}
.menu-content:hover {
  color: var(--website-neutral-60, #c71313);
  /* Website & tablet/Menu */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  cursor: pointer;
}
.active{
  color: var(--website-neutral-60, #c71313);
}
.menu-title {
  color: var(--brand-color, #c71313);
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.right-menu {
  border-radius: 24px;
  border: 1px solid var(--website-gray, #e6e8ec);
  background: var(--website-white, #fff);
  padding: 32px;
}
</style>
